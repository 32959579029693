import Vue from "vue";
import Vuex from "vuex";
import router from "@/router.js";
import { queryString, isWechat } from "@/utils/index.js";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    title: "",
    token: localStorage.getItem("token") || "",
    mobile: localStorage.getItem("mobile") || "",
    hospitalId: localStorage.getItem("hospitalId") || "",
    openid: localStorage.getItem("openid") || "",
    chooseDays: "",
    appointmentDate: "",
    orderPrice: 0,
    addItem: [],
    orderProductName: "",
    appointmentTime: {}, // 预约时间
    hospitalInfo: {},
    fromPage: "",
    viewProductId: null,
    enterpriseCode: localStorage.getItem("enterpriseCode") || "",
    showPrivacy: !localStorage.getItem("showPrivacy"),
    hideHospital: sessionStorage.getItem("hideHospital") || false,
  },
  mutations: {
    setTitle(state, title) {
      state.title = title;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
    },
    setMobile(state, mobile) {
      state.mobile = mobile;
      localStorage.setItem("mobile", mobile);
    },
    setHospitalId(state, hospitalId) {
      state.hospitalId = hospitalId;
      localStorage.setItem("hospitalId", hospitalId);
    },
    setOpenid(state, openid) {
      state.openid = openid;
      localStorage.setItem("openid", openid);
    },
    setChooseDays(state, chooseDays) {
      state.chooseDays = chooseDays;
    },
    setAppointmentDate(state, appointmentDate) {
      state.appointmentDate = appointmentDate;
    },
    setOrderPrice(state, orderPrice) {
      state.orderPrice = orderPrice;
    },
    setOrderProductName(state, orderProductName) {
      state.orderProductName = orderProductName;
    },
    setAddItem(state, item) {
      state.addItem = item;
    },
    setAppointmentTime(state, appointmentTime) {
      state.appointmentTime = appointmentTime;
    },
    setHospitalInfo(state, hospitalInfo) {
      state.hospitalInfo = hospitalInfo;
    },
    setFromPage(state, page) {
      state.fromPage = page;
    },
    setViewProductId(state, productId) {
      state.viewProductId = productId;
    },
    setEnterpriseCode(state, enterpriseCode) {
      state.enterpriseCode = enterpriseCode;
      localStorage.setItem("enterpriseCode", enterpriseCode);
    },
    setShowPrivacy(state, flag) {
      state.showPrivacy = flag;
      localStorage.setItem("showPrivacy", 1);
    },
    //获取openID
    getOpenId(state, to) {
      const { query, path } = to;
      if (isWechat()) {
        //没有openId,则获取openId
        const openId = queryString("openId");

        if (!state.openid) {
          if (!openId) {
            // 没有openId,则跳转获取
            const redirect_uri = encodeURIComponent(location.href);
            const appId = process.env.VUE_APP_APPID;
            const url = `https://open.glztj.com/wxopenIndex?appid=${appId}&appType=1&orginalUrl=${redirect_uri}`;
            location.href = url;
          } else {
            //有openId
            state.openid = openId;
            localStorage.setItem("openid", openId);
            const newQuery = JSON.parse(JSON.stringify(query));
            delete newQuery.openId;
            router.replace({ path: path, query: newQuery });
          }
        }
      }
    },
    setHideHospital(state, flag) {
      state.hideHospital = Boolean(flag);
      sessionStorage.setItem("hideHospital", state.hideHospital);
    },
  },
  actions: {},
});
