<template>
  <div id="app" :style="{ paddingTop: isShowNavBar ? '46px' : 0 }">
    <van-nav-bar v-if="isShowNavBar" fixed :title="title">
      <template #left v-if="showBackButton">
        <van-icon
          name="arrow-left"
          color="#2C2F37"
          onClick="window.history.go(-1)" />
      </template>
    </van-nav-bar>
    <router-view :key="$route.fullPath"></router-view>
    <van-tabbar
      v-if="showTabbar"
      active-color="#00c4b3"
      inactive-color="#989898"
      route
      placeholder
      fixed
      safe-area-inset-bottom>
      <van-tabbar-item replace :to="homeRoute" name="home">
        首页
        <template #icon="props">
          <img
            style="width: 24px; height: 24px"
            :src="props.active ? icon[0].active : icon[0].inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace :to="userRoute" name="profile">
        个人中心
        <template #icon="props">
          <img
            style="width: 24px; height: 24px"
            :src="props.active ? icon[1].active : icon[1].inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <van-popup
      v-model="hasLogin"
      round
      :close-on-click-overlay="false"
      :closeable="showBackButton"
      @click-close-icon="closeLogin">
      <div class="popup-center login-box">
        <div class="login-header" v-if="!hideHospital">
          <div class="logo">
            <img :src="hospitalInfo.logoUrl | filterImg" alt="" />
          </div>
          <div class="hospital-title">
            <p class="bold t-primary font15">{{ hospitalInfo.displayName }}</p>
            <p>请您登录</p>
          </div>
        </div>
        <div class="phone-login">
          <van-field
            v-model.trim="phone"
            left-icon="phone-o"
            placeholder="输入手机号码">
            <template #left-icon>
              <van-icon name="phone-o" />
            </template>
          </van-field>
          <van-field
            v-if="needCheckSms"
            left-icon="shield-o"
            v-model.trim="captchaCodeContent"
            placeholder="输入图形验证码">
            <template #left-icon>
              <van-icon name="shield-o" color="#00c4b3" />
            </template>
            <template #button>
              <img
                style="width: 80px; height: 26px"
                :src="captchaInfo.captchaData"
                alt=""
                @click="getCaptchaImage" />
            </template>
          </van-field>
          <van-field
            v-model.trim="captcha"
            left-icon="sign"
            placeholder="输入验证码"
            :maxlength="6">
            <template #left-icon>
              <van-icon name="sign" />
            </template>
            <template #button>
              <van-button
                class="sms"
                size="small"
                type="primary"
                round
                :disabled="isSend"
                @click="sendSMS">
                <van-count-down
                  v-if="isSend"
                  :time="time"
                  format="ss"
                  @finish="isSend = false"
                  style="display: inline-block" />
                <span v-if="isSend">s</span>
                <span v-else>发送验证码</span>
              </van-button>
            </template>
          </van-field>

          <div class="login-btn need-kown">
            <van-button type="primary" round @click="handlerLogin"
              >确定</van-button
            >
            <van-checkbox
              v-model="needKown"
              shape="square"
              checked-color="#00C4B3">
              登录即表明您同意我们的
              <span class="primary" @click.stop="showPopup = true">
                <span class="primary" @click="ruleType = 'service'">
                  《服务协议》
                </span>
                <span class="primary" @click="ruleType = 'privacy'">
                  《隐私协议》
                </span>
                <span class="primary" @click="ruleType = 'third'">
                  《第三方数据信息共享清单》
                </span>
              </span>
            </van-checkbox>
          </div>
        </div>
      </div>
    </van-popup>
    <van-number-keyboard
      v-model="captcha"
      :show="showKeyboard"
      @blur="showKeyboard = false" />

    <rule :show="showPopup" :type="ruleType" @close="showPopup = false"></rule>
    <privacy
      :showPrivacy="showPrivacy"
      @closePrivacy="closePrivacy"
      @showRule="
        (type) => {
          ruleType = type;
          showPopup = true;
        }
      "></privacy>
  </div>
</template>

<script>
import rule from "@/components/rule.vue";
import privacy from "@/components/privacy.vue";
import {
  SendSMS,
  login,
  GetToken,
  GetHospital,
  needCheckValidate,
  getCaptcha,
} from "@/api";
import homeActive from "@/assets/tabbar/home-active.png";
import homeInactive from "@/assets/tabbar/home-default.png";
import userActive from "@/assets/tabbar/user-active.png";
import userInactive from "@/assets/tabbar/user-default.png";
export default {
  name: "App",
  components: {
    rule,
    privacy,
  },
  computed: {
    title() {
      return this.$store.state.title;
    },
    hospitalId() {
      return this.$store.state.hospitalId;
    },
    homeRoute() {
      return {
        path: "/home",
        query: {
          hospitalId: this.$store.state.hospitalId,
        },
      };
    },
    userRoute() {
      return {
        path: "/person-center",
        query: {
          hospitalId: this.$store.state.hospitalId,
        },
      };
    },
    hasLogin: {
      get() {
        return (
          !this.$store.state.token && this.loginBlack.includes(this.$route.name)
        );
      },
      set() {},
      // return false
    },
    hospitalInfo() {
      if (this.$store.state.hospitalInfo?.displayName) {
        return this.$store.state.hospitalInfo;
      } else {
        return localStorage.getItem("hospitalInfo")
          ? JSON.parse(localStorage.getItem("hospitalInfo"))
          : {};
      }
    },
    openid() {
      return this.$store.state.openid;
    },
    showTabbar() {
      return ["home", "person-center"].includes(this.$route.name);
    },
    showPrivacy() {
      return this.$store.state.showPrivacy;
    },
    hideHospital() {
      return this.$store.state.hideHospital
    },
    showBackButton() {
      return window.history.length > 1 && !this.hideHospital;
    },
  },
  data() {
    return {
      ruleType: "",
      captcha: "",
      phone: "",
      showKeyboard: false,
      needKown: false,
      isSend: false,
      time: 60 * 1000,
      showPopup: false,
      loginBlack: [
        "confirm-order",
        "confirm-pay",
        "person-center",
        "questionnaire",
        "report-list",
        "order-list",
        "order-detail",
      ],
      code: "",
      navRouteName: [
        "home",
        "index",
        "add-item",
        "group-poster",
        "group-profile",
        "group-index",
        "group-login",
        "group-apply",
        "group-apply-status",
        "questionnaire",
        "questionnaire-result",
        "report-list",
        "questionnaire-recommend",
        "questionnaire-business",
        "group-contact-list",
        "group-contact-detail",
        "person-center",
      ],
      personCenterRouteName: [
        "person-center",
        "index",
        "add-item",
        "confirm-order",
      ],
      isShowNavBar: true,
      isShowPersonCenter: true,
      icon: [
        {
          active: homeActive,
          inactive: homeInactive,
        },
        {
          active: userActive,
          inactive: userInactive,
        },
      ],
      needCheckSms: false,
      captchaInfo: {},
      captchaCodeContent: "",
    };
  },
  watch: {
    $route: {
      immediate: false,
      handler(route) {
        const { hospitalId, hideHospital } = route.query;
        if (hospitalId) {
          this.$store.commit("setHospitalId", hospitalId);
        }

        if(hideHospital){
          this.$store.commit('setHideHospital', hideHospital)
        }

        if (this.navRouteName.includes(route.name)) {
          this.isShowNavBar = false;
        } else {
          this.isShowNavBar = true;
        }
        console.log(route);
        if (this.personCenterRouteName.includes(route.name)) {
          this.isShowPersonCenter = false;
        } else {
          this.isShowPersonCenter = true;
        }
      },
    },
    hasLogin(newVal) {
      if (newVal && this.$store.state.hospitalId) {
        this.getHospital();
        this.getNeedCheckValidate();
      }
    },
    hospitalId(newVal) {
      if (newVal) {
        this.getHospital();
      }
    },
    needCheckSms(val) {
      if (val) {
        this.getCaptchaImage();
      }
    },
  },
  methods: {
    closeLogin() {
      this.needCheckSms = false;
      switch (this.$route.name) {
        case "person-center":
          this.$router.replace(
            "/home?hospitalId=" + this.$store.state.hospitalId
          );
          break;
        case "report-list":
          this.$router.push("/home?hospitalId=" + this.$store.state.hospitalId);
          break;
        case "confirm-order":
          this.$router.replace("/good-detail/" + this.$route.params.id);
          break;
        case "good-detail":
          this.$router.push("/home?hospitalId=" + this.$store.state.hospitalId);
          break;
        default:
          this.$router.back();
      }

      console.log("close");
    },
    async sendSMS() {
      if (this.needCheckSms && !this.captchaCodeContent) {
        this.$toast("请输入图形验证码");
        return;
      }
      if (!this.$testPhone(this.phone)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      let token = await GetToken();

      const params = {
        phone: this.phone,
        templeteId: 745178,
        businessType: 10,
        token: token,
        captchaCodeContent: this.captchaCodeContent,
        captchaCodeId: this.captchaInfo.captchaId,
      };
      let data = await SendSMS(params).catch(() => {
        this.getCaptchaImage();
      });
      if (data) {
        this.isSend = true;
        this.$toast("发送短信成功！");
      }
    },
    async handlerLogin() {
      if (!this.needKown) {
        this.$toast("请同意协议");
        return;
      }
      if (!this.captcha) {
        this.$toast("请输入验证码");
        return;
      }
      const params = {
        mobile: this.phone,
        captcha: this.captcha,
        openId: this.openid,
      };
      let data = await login(params).catch(() => {
        this.captcha = "";
        this.getNeedCheckValidate();
      });
      if (data) {
        this.$store.commit("setToken", data);
        this.$store.commit("setMobile", this.phone);
        this.phone = "";
        this.captcha = "";
        this.captchaCodeContent = "";
        this.needKown = false;
      }
    },
    async getHospital() {
      let data = await GetHospital({
        HospitalId: this.$store.state.hospitalId,
        HospitalCode: this.$store.state.hospitalId,
      });
      localStorage.setItem("hospitalInfo", JSON.stringify(data));
      localStorage.setItem("telNo", data.telNo);
      this.$store.commit("setHospitalInfo", data);
      if(!this.hideHospital){
        this.$setTitle(data.displayName);
      }
      return data;
    },
    closePrivacy() {
      this.$store.commit("setShowPrivacy", false);
    },
    async getNeedCheckValidate() {
      const res = await needCheckValidate();
      this.needCheckSms = res;
    },
    async getCaptchaImage() {
      const res = await getCaptcha();
      this.captchaInfo = res;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  .person-center-icon {
    position: absolute;
    width: 72px;
    height: 40px;
    display: flex;
    padding: 6px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    right: 0;
    bottom: 102px;
    background: #00c4b3;
    color: #fff;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(115, 198, 191, 0.3);
    z-index: 2;
    overflow: hidden;
    .icon {
      height: 28px;
      width: 28px;
      border-radius: 14px;
      padding: 6px;
      background: #fff;
      margin-right: 4px;
    }
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .login-box {
    padding: 0;
    .sms {
      width: 84px;
      color: #fff;
      .van-count-down {
        color: #fff;
      }
    }
    .login-header {
      height: 100px;
      width: 100%;
      background: #f5f6f8;
      padding: 0 31px;
      display: flex;
      align-items: center;
      .hospital-title {
        font-size: 13px;
        color: #6e7886;
        line-height: 1.6;
      }
      .logo {
        width: 58px;
        height: 58px;
        margin-right: 11px;
      }
    }
    .phone-login {
      padding: 28px 30px;
      .van-cell {
        padding: 10px 0;
        &::after {
          border-color: #dcdcdc;
        }
      }
      .van-field__left-icon {
        margin-right: 10px;
        .van-icon.van-icon-phone-o {
          color: #00c4b3;
        }
        .van-icon.van-icon-sign {
          color: #00c4b3;
        }
      }
      .van-password-input {
        margin: 0;
      }
      .van-password-input__item {
        border-color: #e5e5e5;
        & + .van-password-input__item {
          margin-left: 6px;
        }
      }
    }
    .need-kown {
      margin: 40px 18px 20px;
      .van-button {
        width: 100%;
        margin-bottom: 18px;
      }
      .van-checkbox__icon {
        height: unset;
        vertical-align: middle;
      }
      .van-checkbox {
        margin: 0 auto;
      }
      .van-icon-success {
        font-size: 12px;
      }
    }
  }
  .van-number-keyboard {
    z-index: 1111111111111;
  }
  .full-page {
    width: 100%;
    height: 80vh;
    max-height: 80vh;
    z-index: 1111111111111111 !important;
  }
}
</style>
